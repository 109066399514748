<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-card-text class="d-flex">
        <v-avatar rounded size="120" class="me-6">
          <v-img :src="cropped"></v-img>
        </v-avatar>
      </v-card-text>

      <v-card-text>
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-row>
            <v-col md="3" cols="12">
              <v-text-field readonly v-model="branch" label="Branch" dense outlined></v-text-field>
            </v-col>
            <v-col md="3" cols="12">
              <v-text-field
                v-model="fsc_no"
                label="FSC No."
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                readonly
              ></v-text-field>
            </v-col>
            <v-col md="3" cols="12">
              <v-text-field
                type="date"
                v-model="date_of_service"
                label="Date of Service"
                dense
                outlined
                @change="calculate_all_payables"
                @keyup="calculate_all_payables"
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col md="3" cols="12">
              <v-text-field
                readonly
                v-model="date_of_internment"
                label="Date of Interment"
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-card flat>
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">DECEASED PERSONAL INFOMATION</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-col md="4" cols="12"></v-col>
                <v-row>
                  <v-col md="4" cols="12">
                    <v-text-field
                      v-model="last_name"
                      label="Last Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col md="4" cols="12">
                    <v-text-field
                      v-model="first_name"
                      label="First Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" cols="12">
                    <v-text-field
                      v-model="middle_name"
                      label="Middle Name"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-text-field
                      v-model="date_of_birth"
                      label="Date of Birth"
                      dense
                      outlined
                      @change="calculate_all_payables"
                      @keyup="calculate_all_payables"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      type="date"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                    <v-text-field v-model="age" label="Age" dense outlined readonly></v-text-field>
                  </v-col>

                  <v-col cols="12" md="5">
                    <v-text-field
                      v-model="date_of_death"
                      label="Date of Death"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                      type="date"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field
                      v-model="religion"
                      dense
                      outlined
                      label="Religion"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      v-model="civil_status"
                      dense
                      outlined
                      label="Civil Status"
                      :items="['Single', 'Married', 'Widowed', 'Separated']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                      v-model="gender"
                      dense
                      outlined
                      label="Gender"
                      :items="['Male', 'Female']"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card flat>
              <v-toolbar dense dark color="primary">
                <v-toolbar-title
                ><h4 class="font-weight-light">FAMILY REPRESENTATIVES INFORMATION</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-col md="4" cols="12"></v-col>
                <v-row>
                  <v-col md="4" cols="12">
                    <v-text-field
                      v-model="representatives_name"
                      label="Name"
                      dense
                      outlined
                      :rules="rules.default_max_100_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>

                  <v-col md="4" cols="12">
                    <v-text-field
                      v-model="representatives_relationship"
                      label="Relationship"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" cols="12">
                    <v-text-field
                      v-model="representatives_contact_no"
                      label="Contact #"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col md="12" cols="12">
                    <v-text-field
                      v-model="representatives_address"
                      label="Address"
                      dense
                      outlined
                      :rules="rules.default_max_255_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">SERVICE DATA INFORMATION</h4>
                </v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-col md="4" cols="12"></v-col>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-select
                      v-model="casket_type"
                      dense
                      outlined
                      label="Casket Type"
                      :items="caskets_available"
                      item-value="casket_type"
                      item-text="casket_type"
                      :rules="rules.combobox_rule"
                      @change="
                        selected_casket(
                          caskets_available
                            .map(function (x) {
                              return x.casket_type
                            })
                            .indexOf(casket_type),
                        )
                      "
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      v-model="mode_of_service"
                      dense
                      outlined
                      label="Mode of Service"
                      :items="mode_of_service_items"
                      item-value="mode_of_service"
                      item-text="mode_of_service"
                      :rules="rules.combobox_rule"
                      @change="
                        selected_mode_of_service(
                          mode_of_service_items
                            .map(function (x) {
                              return x.mode_of_service
                            })
                            .indexOf(mode_of_service),
                        )
                      "
                    ></v-select>
                  </v-col>
                  <v-col
                    md="3"
                    cols="12"
                    v-if="
                      mode_of_service === 'Damayan HG Package' ||
                      mode_of_service === 'Damayan FG Package' ||
                      mode_of_service === 'Damayan HG Package Upgrade' ||
                      mode_of_service === 'Damayan FG Package Upgrade' ||
                      mode_of_service === 'Damayan Metal Package' ||
                      mode_of_service === 'Damayan Metal Package Upgrade'
                    "
                  >
                    <v-text-field
                      v-model="maf_no"
                      label="Maf #"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    md="3"
                    cols="12"
                    v-if="
                      mode_of_service === 'Damayan HG Package' ||
                      mode_of_service === 'Damayan FG Package' ||
                      mode_of_service === 'Damayan HG Package Upgrade' ||
                      mode_of_service === 'Damayan FG Package Upgrade' ||
                      mode_of_service === 'Damayan Metal Package' ||
                      mode_of_service === 'Damayan Metal Package Upgrade'
                    "
                  >
                    <v-text-field
                      v-model="damayan_branch"
                      label="Damayan Branch"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    v-if="
                      mode_of_service === 'Damayan HG Package' ||
                      mode_of_service === 'Damayan FG Package' ||
                      mode_of_service === 'Damayan HG Package Upgrade' ||
                      mode_of_service === 'Damayan FG Package Upgrade' ||
                      mode_of_service === 'Damayan Metal Package' ||
                      mode_of_service === 'Damayan Metal Package Upgrade'
                    "
                  >
                    <v-select
                      v-model="damayan_category"
                      dense
                      outlined
                      label="Category"
                      :items="['PAY THE BALANCE', 'SPOT SERVICE', 'WAIVED']"
                      :rules="rules.combobox_rule"
                      @change="calculate_all_payables"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    v-if="
                      mode_of_service === 'Damayan HG Package' ||
                      mode_of_service === 'Damayan FG Package' ||
                      mode_of_service === 'Damayan HG Package Upgrade' ||
                      mode_of_service === 'Damayan FG Package Upgrade' ||
                      mode_of_service === 'Damayan Metal Package' ||
                      mode_of_service === 'Damayan Metal Package Upgrade'
                    "
                  >
                    <v-text-field
                      v-model="payable_on_damayan"
                      label="Payable on Damayan"
                      dense
                      outlined
                      type="number"
                      min="0"
                      @change="calculate_all_payables"
                      @keyup="calculate_all_payables"
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    v-if="
                      mode_of_service === 'Kaagapay Mem' ||
                      mode_of_service === 'Kaagapay IFam' ||
                      mode_of_service === 'Kaagapay Rel' ||
                      mode_of_service === 'Kaagapay N-Rel'
                    "
                  >
                    <v-combobox
                      :append-icon="icons.mdiAccountSearchOutline"
                      v-model="kaagapay_id"
                      @keyup="searching($event.target.value)"
                      :items="kaagapay_items"
                      item-value="id"
                      item-text="name"
                      placeholder="Search Kaagapay by (Lastname/Firstname/Kmf No)"
                      ref="memberCombobox"
                      outlined
                      :rules="rules.default_no_empty_rule"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="12" md="3" v-if="mode_of_service === 'Kaagapay Mem'">
                    <v-select
                      v-model="kaagapay_category"
                      dense
                      outlined
                      label="Kaagapay Category"
                      :items="['Member']"
                      :rules="rules.combobox_rule"
                      @change="calculate_all_payables"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3" v-if="mode_of_service === 'Kaagapay IFam'">
                    <v-select
                      v-model="kaagapay_category"
                      dense
                      outlined
                      label="Kaagapay Category"
                      :items="['Immediate Family']"
                      :rules="rules.combobox_rule"
                      @change="calculate_all_payables"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3" v-if="mode_of_service === 'Kaagapay Rel'">
                    <v-select
                      v-model="kaagapay_category"
                      dense
                      outlined
                      label="Kaagapay Category"
                      :items="['Relatives']"
                      :rules="rules.combobox_rule"
                      @change="calculate_all_payables"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3" v-if="mode_of_service === 'Kaagapay N-Rel'">
                    <v-select
                      v-model="kaagapay_category"
                      dense
                      outlined
                      label="Kaagapay Category"
                      :items="['Non-Relatives']"
                      :rules="rules.combobox_rule"
                      @change="calculate_all_payables"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      v-model="description"
                      dense
                      outlined
                      label="Description"
                      :items="caskets_pricing"
                      item-value="description"
                      item-text="description"
                      :rules="rules.combobox_rule"
                      @change="
                        pricing(
                          caskets_pricing
                            .map(function (x) {
                              return x.description
                            })
                            .indexOf(description),
                        )
                      "
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    v-if="mode_of_service === 'Damayan HG Package' || mode_of_service === 'Damayan HG Package Upgrade'"
                  >
                    <v-select
                      v-if="description === 'Regular (RUBY/TOPAZ)' || description === 'Senior (RUBY/TOPAZ)'"
                      v-model="damayan_package"
                      dense
                      outlined
                      label="Damayan Package"
                      :items="['RUBY', 'TOPAZ']"
                      :rules="rules.combobox_rule"
                      @change="calculate_all_payables"
                    ></v-select>
                    <v-select
                      v-if="description === 'Regular (AMBER/ZIRCON)' || description === 'Senior (AMBER/ZIRCON)'"
                      v-model="damayan_package"
                      dense
                      outlined
                      label="Damayan Package"
                      :items="['AMBER', 'ZIRCON']"
                      :rules="rules.combobox_rule"
                      @change="calculate_all_payables"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    v-if="mode_of_service === 'Damayan FG Package' || mode_of_service === 'Damayan FG Package Upgrade'"
                  >
                    <v-select
                      v-if="description === 'Regular (EMERALD/SAPPHIRE)' || description === 'Senior (EMERALD/SAPPHIRE)'"
                      v-model="damayan_package"
                      dense
                      outlined
                      label="Damayan Package"
                      :items="['EMERALD', 'SAPPHIRE']"
                      :rules="rules.combobox_rule"
                      @change="calculate_all_payables"
                    ></v-select>
                    <v-select
                      v-if="description === 'Regular (OPAL/CITRINE)' || description === 'Senior (OPAL/CITRINE)'"
                      v-model="damayan_package"
                      dense
                      outlined
                      label="Damayan Package"
                      :items="['OPAL', 'CITRINE']"
                      :rules="rules.combobox_rule"
                      @change="calculate_all_payables"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    v-if="
                      mode_of_service === 'Damayan Metal Package' || mode_of_service === 'Damayan Metal Package Upgrade'
                    "
                  >
                    <v-select
                      v-if="description === 'Regular (DIAMOND)' || description === 'Senior (DIAMOND)'"
                      v-model="damayan_package"
                      dense
                      outlined
                      label="Damayan Package"
                      :items="['DIAMOND']"
                      :rules="rules.combobox_rule"
                      @change="calculate_all_payables"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                    v-if="
                      mode_of_service === 'Damayan HG Package' ||
                      mode_of_service === 'Damayan FG Package' ||
                      mode_of_service === 'Damayan HG Package Upgrade' ||
                      mode_of_service === 'Damayan FG Package Upgrade' ||
                      mode_of_service === 'Damayan Metal Package' ||
                      mode_of_service === 'Damayan Metal Package Upgrade'
                    "
                  >
                    <v-text-field
                      v-model="payable_on_chapel"
                      label="Client Chapel Payables"
                      dense
                      outlined
                      @change="calculate_all_payables"
                      @keyup="calculate_all_payables"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      readonly
                      v-model="casket_price_format"
                      label="Casket Price"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      readonly
                      v-model="casket_price_discount_format"
                      label="Casket Discount"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      readonly
                      v-model="casket_price_new_format"
                      label="Casket Net Price"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      type="number"
                      min="0"
                      v-model="kilometer"
                      label="Kilometers"
                      dense
                      outlined
                      @change="calculate_all_payables"
                      @keyup="calculate_all_payables"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      readonly
                      v-model="kilometer_excess"
                      label="Kilometers Excess"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      type="number"
                      min="0"
                      v-model="days_of_embalming"
                      label="Day's of Embalming"
                      dense
                      outlined
                      @change="calculate_all_payables"
                      @keyup="calculate_all_payables"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      readonly
                      v-model="embalming_excess"
                      label="Embalming Excess"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      v-model="viewing_type"
                      dense
                      outlined
                      label="Viewing Type"
                      :items="viewing_type_data"
                      item-value="id"
                      item-text="viewing_type"
                      @change="calculate_all_payables"
                      :rules="rules.combobox_rule"
                    ></v-select>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      type="number"
                      min="0"
                      v-model="chapel_view"
                      label="Viewing Days"
                      dense
                      outlined
                      @change="calculate_all_payables"
                      @keyup="calculate_all_payables"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      readonly
                      v-model="viewing_excess"
                      label="Viewing Excess"
                      dense
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      type="number"
                      min="0"
                      v-model="shipment_fee"
                      label="Shipment Fee"
                      dense
                      outlined
                      @change="calculate_all_payables"
                      @keyup="calculate_all_payables"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      type="number"
                      min="0"
                      v-model="tribute_fee"
                      label="Tribute Fee"
                      dense
                      outlined
                      @change="calculate_all_payables"
                      @keyup="calculate_all_payables"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      type="number"
                      min="0"
                      v-model="hospital_bill"
                      label="Hospital Bill"
                      dense
                      outlined
                      @change="calculate_all_payables"
                      @keyup="calculate_all_payables"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field
                      type="number"
                      min="0"
                      v-model="other_charges"
                      label="Other Charges"
                      dense
                      outlined
                      @change="calculate_all_payables"
                      @keyup="calculate_all_payables"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12" v-if="other_charges > 0">
                    <v-text-field
                      min="0"
                      v-model="others_reason"
                      label="Specify (Others)"
                      dense
                      outlined
                      :rules="rules.default_max_45_character_and_no_empty_rule"
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" cols="12">
                    <v-text-field readonly v-model="total_format" label="Total" dense
                                  outlined></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-checkbox
                v-if="mode_of_service === 'Direct'||mode_of_service.includes('Kaagapay')||mode_of_service.includes('Upgrade')"
                label="Have Callers"
                v-model="is_have_callers" hide-details
                class="me-3 mt-1" @change="calculate_all_payables"></v-checkbox>
              <div
                v-if="(mode_of_service === 'Direct'||mode_of_service.includes('Kaagapay')||mode_of_service.includes('Upgrade')) && is_have_callers">
                <v-toolbar dense dark color="primary">
                  <v-toolbar-title><h4 class="font-weight-light">CALLERS INFORMATION</h4>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-col md="4" cols="12"></v-col>
                  <v-row>
                    <v-col cols="12" md="3" v-if="mode_of_service === 'Direct'">
                      <v-select
                        v-model="caller_percentage"
                        dense
                        outlined
                        label="Caller's Percentage"
                        :items="['0%', '10%', '15%']"
                        @change="calculate_all_payables"
                        :rules="rules.combobox_rule"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="3" v-if="mode_of_service.includes('Kaagapay')||mode_of_service.includes('Upgrade')">
                      <v-select
                        v-model="caller_percentage"
                        dense
                        outlined
                        label="Caller's Percentage"
                        :items="['10%']"
                        @change="calculate_all_payables"
                        :rules="rules.combobox_rule"
                      ></v-select>
                    </v-col>

                    <v-col md="3" cols="12">
                      <v-text-field v-model="callers" label="Caller's Amount" readonly dense
                                    outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select
                        v-model="callers_id"
                        dense
                        outlined
                        label="Caller's"
                        :items="callers_items"
                        item-value="id"
                        item-text="full_name"
                        :rules="rules.combobox_rule"
                        @change="calculate_all_payables"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </div>
            </v-card>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert color="warning" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{ alert_message }}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" class="me-3 mt-4" @click="save_member" v-if="!saving"> Save
                changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiAccountSearchOutline} from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      cropped: require('@/assets/images/avatars/1.png'),

      saving: false,
      alert: false,
      alert_message: '',

      accomodation_charge: 0,
      callers_id: '',
      fsc_no: '',
      last_name: '',
      first_name: '',
      middle_name: '',
      date_of_birth: '',
      age: 0,
      gender: '',
      civil_status: '',
      religion: '',
      representatives_name: '',
      representatives_address: '',
      representatives_contact_no: '',
      representatives_relationship: '',
      date_of_death: '',
      date_of_internment: '',
      date_of_service: '',
      mode_of_service: '',
      kaagapay_category: '',
      damayan_branch: '',
      maf_no: '',
      casket_type: '',
      casket_production: 0,
      po_no: '',
      description: '',
      kilometer: 25,
      upgrade_service: 0,
      casket_price_new: 0,
      casket_price_new_format: '',
      casket_price: 0,
      discount: 0,
      casket_price_format: '',
      casket_price_discount_format: '',
      callers: 0,
      kilometer_excess: 0,
      chapel_view_free_days: 0,
      embalming_free_days: 0,
      embalming_excess_charge: 0,
      days_of_embalming: 9,
      embalming_excess: 0,
      viewing_type_room: '',
      viewing_type: '',
      viewing_type_excess_charge: 0,
      chapel_view: 0,
      viewing_excess: 0,
      other_charges: 0,
      others_reason: '',
      special_price: 0,
      shipment_fee: 0,
      tribute_fee: 0,
      hospital_bill: 0,
      ho_share: 0,
      total: 0,
      total_format: 0,
      payable_on_chapel: 0,
      charges: 0,

      caller_percentage: '',
      callers_items: [],
      caskets_available: [],
      caskets_pricing: [],
      mode_of_service_items: [],

      viewing_type_data: [],

      kaagapay_id: 0,
      search_kaagapay: '',
      kaagapay_items: [],
      casket_inventory_id: 0,
      damayan_package: '',
      damayan_category: '',
      chapel_manager: '',
      payable_on_damayan: 0,
      is_have_callers: false,
    }
  }

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        show: false,
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountSearchOutline,
        },
      }
    },
    created() {
      this.initialize_data()
    },
    data() {
      return initialState()
    },
    watch: {
      search_kaagapay: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_kaagapay === '') {
            this.kaagapay_items = []
          } else {
            this.searching_kaagapay_items(this.search_kaagapay)
          }
        }, 100)
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', [
        'branch',
        'branch_id',
        'year',
        'month_start',
        'month_end',
        'month_of',
        'user_id',
        'branch_address',
        'branch_contact_no',
      ]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('deceased_client', ['save_new_deceased_client', 'deceased_counter']),
      ...mapActions('casket_inventory', ['get_available_casket']),
      ...mapActions('chapel_viewing_type', ['get_chapel_view_type']),
      ...mapActions('staff', ['find_staff_with_position_active']),
      ...mapActions('kaagapay', ['search_kaagapay_paid_and_not_used']),
      ...mapActions('users', ['get_chapel_manager_name']),
      initialize_data() {
        const data = new FormData()
        data.append('year', this.year)
        this.deceased_counter(data)
          .then(response => {
            this.fsc_no = response.data + 1 + '-' + moment().format('YYYY')
          })
          .catch(error => {
            this.saving = false
          })
        const data2 = new FormData()
        data2.append('branch_id', this.branch_id)
        this.get_available_casket(data2)
          .then(response => {
            this.caskets_available = response.data
          })
          .catch(error => {
            this.saving = false
          })
        this.get_chapel_view_type(data2)
          .then(response => {
            this.viewing_type_data = response.data
          })
          .catch(error => {
            this.saving = false
          })
        this.get_chapel_manager_name(data2)
          .then(response => {
            this.chapel_manager = response.data[0].name
          })
          .catch(error => {
            this.saving = false
          })
        const data3 = new FormData()
        data3.append('branch_id', this.branch_id)
        data3.append('positions', 'Callers')
        this.find_staff_with_position_active(data3)
          .then(response => {
            this.callers_items = response.data
          })
          .catch(error => {
            this.saving = false
          })
      },
      reset() {
        Object.assign(this.$data, initialState())
        this.initialize_data()
      },
      searching(value) {
        this.search_kaagapay = value
      },
      searching_kaagapay_items(value) {
        const data2 = new FormData()
        data2.append('branch_id', this.branch_id)
        data2.append('search_word', value)
        this.search_kaagapay_paid_and_not_used(data2)
          .then(response => {
            this.kaagapay_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      selected_casket(index) {
        this.ho_share = 0
        this.casket_inventory_id = this.caskets_available[index].id
        this.po_no = this.caskets_available[index].po_no
        this.mode_of_service_items = this.caskets_available[index].casket_pricing
        this.mode_of_service = ''
        this.description = ''
        this.casket_price = 0
        this.discount = 0
        this.casket_price_new = 0
        this.casket_price_format = ''
        this.casket_price_discount_format = ''
        this.casket_price_new_format = ''
        this.calculate_all_payables()
      },
      selected_mode_of_service(index) {
        this.ho_share = 0
        this.caskets_pricing = this.mode_of_service_items[index].data
        this.damayan_package = ''
        this.damayan_category = ''
        this.payable_on_damayan = 0
        this.damayan_branch = ''
        this.description = ''
        this.casket_price = 0
        this.casket_price_new = 0
        this.discount = 0
        this.casket_price_format = ''
        this.casket_price_discount_format = ''
        this.casket_price_new_format = ''
        this.kaagapay_id = 0
        this.kaagapay_category = ''
        this.calculate_all_payables()
      },
      pricing(index) {
        this.ho_share = this.caskets_pricing[index].ho_share
        this.casket_price_format = this.formatPrice(this.caskets_pricing[index].total)
        this.casket_price_discount_format = this.formatPrice(this.caskets_pricing[index].discount)
        this.discount = this.caskets_pricing[index].discount
        this.casket_price = this.caskets_pricing[index].total
        this.casket_price_new = this.caskets_pricing[index].amount
        this.casket_price_new_format = this.formatPrice(this.caskets_pricing[index].amount)
        this.casket_production = this.caskets_pricing[index].production
        this.embalming_free_days = this.caskets_pricing[index].embalming_free_days
        this.embalming_excess_charge = this.caskets_pricing[index].embalming_excess_charge
        this.chapel_view_free_days = this.caskets_pricing[index].chapel_view_free_days
        this.calculate_all_payables()
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      calculate_all_payables() {
        // this.payable_on_chapel = parseFloat(this.casket_price_new) - parseFloat(this.payable_on_damayan)
        this.payable_on_chapel = parseFloat(this.casket_price_new)
        if (parseFloat(this.payable_on_chapel) < 0) {
          this.payable_on_chapel = 0
        }
        this.age = moment(this.date_of_service, 'YYYY-MM-DD').diff(moment(this.date_of_birth, 'YYYY-MM-DD'), 'years')
        this.date_of_internment = moment(this.date_of_service, 'YYYY-MM-DD')
          .add(parseInt(this.days_of_embalming), 'days')
          .format('MMMM D, YYYY')

        //Kilometer
        this.kilometer_excess = (this.kilometer - 25) * 50
        if (this.kilometer_excess <= 0) {
          this.kilometer = 25
          this.kilometer_excess = 0
        }
        if (this.damayan_branch === 'LIGAO' || this.damayan_branch === 'POLANGUI') {
          this.kilometer_excess = 0
        }

        //Embalming
        this.embalming_excess = (this.days_of_embalming - this.embalming_free_days) * this.embalming_excess_charge
        if (this.embalming_excess <= 0) {
          this.days_of_embalming = this.embalming_free_days
          this.embalming_excess = 0
        }

        //Chapel View
        var chapel_view_free = this.chapel_view_free_days
        var indexViewingType = this.viewing_type_data
          .map(function (x) {
            return x.id
          })
          .indexOf(this.viewing_type)
        if (indexViewingType != -1) {
          this.viewing_type_excess_charge = this.viewing_type_data[indexViewingType].amount
          this.viewing_type_room = this.viewing_type_data[indexViewingType].viewing_type
          if (this.chapel_view_free_days > 0) {
            switch (this.viewing_type_room) {
              case 'TOPAZ NON-AIR':
              case 'SAPPHIRE NON-AIR':
                break
              default:
                chapel_view_free = 0
                break
            }
          }
          this.viewing_excess = (this.chapel_view - chapel_view_free) * this.viewing_type_excess_charge
        }
        if (this.viewing_excess <= 0) {
          this.chapel_view = chapel_view_free
          this.viewing_excess = 0
        }
        // if (this.damayan_category === 'WAIVED') {
        //   this.payable_on_damayan = 0
        // }
        // this.chapel_share = parseFloat(this.casket_price_new) - parseFloat(this.payable_on_damayan)
        this.chapel_share = parseFloat(this.casket_price_new)
        if (parseFloat(this.chapel_share) < 0) {
          this.chapel_share = 0
        }
        // this.ho_share = 0
        // if (this.mode_of_service === 'Damayan HG Package' || this.mode_of_service === 'Damayan HG Package Upgrade') {
        //   this.ho_share = 10000
        // } else if (
        //   this.mode_of_service === 'Damayan FG Package' ||
        //   this.mode_of_service === 'Damayan FG Package Upgrade'
        // ) {
        //   this.ho_share = 16000
        // }
        //Damayan Upgrade
        if (this.mode_of_service === 'Damayan HG Package Upgrade') {
          this.upgrade_service = 1
        } else if (this.mode_of_service === 'Damayan FG Package Upgrade') {
          this.upgrade_service = 1
        }
        this.charges = parseFloat(this.kilometer_excess) +
          parseFloat(this.embalming_excess) +
          parseFloat(this.viewing_excess) +
          parseFloat(this.shipment_fee) +
          parseFloat(this.tribute_fee) +
          parseFloat(this.hospital_bill) +
          parseFloat(this.other_charges)
        this.total =
          parseFloat(this.casket_price_new) +
          parseFloat(this.special_price) +
          parseFloat(this.charges)

        this.total_format = this.formatPrice(this.total)
        if ((this.mode_of_service === 'Direct' ||this.mode_of_service.includes('Kaagapay')||this.mode_of_service.includes('Upgrade'))&& this.is_have_callers) {
          var selected_percentage = 0
          switch (this.caller_percentage) {
            case '10%':
              selected_percentage = 0.1
              break
            case '15%':
              selected_percentage = 0.15
              break
          }
          this.callers = selected_percentage * parseFloat(this.casket_price_new)
          var indexCaller = this.callers_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.callers_id)

          if (indexCaller > -1) {
            if (this.callers_items[indexCaller].last_name === 'GOODLIFE') {
              this.callers = 0
            }
          }
        } else if (this.mode_of_service.includes('Kaagapay') && this.is_have_callers) {
          this.callers = this.casket_type.includes('Ordinary') ? 500 : 1000;
          var indexCaller = this.callers_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.callers_id)

          if (indexCaller > -1) {
            if (this.callers_items[indexCaller].last_name === 'GOODLIFE') {
              this.callers = 0
            }
          }
        } else {
          this.callers_id = ''
          this.callers = 0
        }
      },
      save_member() {
        this.saving = true
        this.alert = false
        var flag = false
        if (
          moment(this.date_of_service, 'YYYY-MM-DD').format('MMMM D, YYYY') ===
          moment(this.month_start, 'MMMM D, YYYY').format('MMMM D, YYYY') ||
          moment(this.date_of_service, 'YYYY-MM-DD').format('MMMM D, YYYY') ===
          moment(this.month_end, 'MMMM D, YYYY').format('MMMM D, YYYY')
        ) {
          flag = true
        } else {
          var compareDate = moment(this.date_of_service, 'YYYY-MM-DD')
          var startDate = moment(this.month_start, 'MMMM D, YYYY')
          var endDate = moment(this.month_end, 'MMMM D, YYYY')

          flag = compareDate.isBetween(startDate, endDate) //false in this case
        }
        if (flag === false) {
          this.date_of_service = ''
          this.alert = true
          this.alert_message = 'Date of Service is Not Belong in Transaction Month!'
          this.saving = false
        } else {
          var kagapay_id = ''
          if (
            this.mode_of_service === 'Kaagapay Mem' ||
            this.mode_of_service === 'Kaagapay IFam' ||
            this.mode_of_service === 'Kaagapay Rel' ||
            this.mode_of_service === 'Kaagapay N-Rel'
          ) {
            kagapay_id = this.kaagapay_id.id
          }
          if (this.$refs.form.validate()) {
            const data = new FormData()
            data.append('branch_id', this.branch_id)
            data.append('callers_id', this.callers_id)
            data.append('fsc_no', this.fsc_no)
            data.append('last_name', this.last_name.toUpperCase())
            data.append('first_name', this.first_name.toUpperCase())
            data.append('middle_name', this.middle_name.toUpperCase())
            data.append('date_of_birth', moment(this.date_of_birth, 'YYYY-MM-DD').format('MMMM D, YYYY'))
            data.append('age', this.age)
            data.append('gender', this.gender)
            data.append('civil_status', this.civil_status)
            data.append('religion', this.religion.toUpperCase())
            data.append('representatives_name', this.representatives_name.toUpperCase())
            data.append('representatives_address', this.representatives_address.toUpperCase())
            data.append('representatives_contact_no', this.representatives_contact_no.toUpperCase())
            data.append('representatives_relationship', this.representatives_relationship.toUpperCase())
            data.append('date_of_death', moment(this.date_of_death, 'YYYY-MM-DD').format('MMMM D, YYYY'))
            data.append('date_of_internment', this.date_of_internment)
            data.append('date_of_service', moment(this.date_of_service, 'YYYY-MM-DD').format('MMMM D, YYYY'))
            data.append('month_of', this.month_of)
            data.append('mode_of_service', this.mode_of_service)
            data.append('kaagapay_category', this.kaagapay_category)
            data.append('kaagapay_id', kagapay_id)
            data.append('damayan_branch', this.damayan_branch.toUpperCase())
            data.append('maf_no', this.maf_no)
            data.append('casket_type', this.casket_type)
            data.append('casket_production', this.casket_production)
            data.append('po_no', this.po_no)
            data.append('description', this.description)
            data.append('kilometer', this.kilometer)
            data.append('upgrade_service', this.upgrade_service)
            data.append('casket_price', this.casket_price_new)
            data.append('callers', this.callers)
            data.append('kilometer_excess', this.kilometer_excess)
            data.append('embalming_free_days', this.embalming_free_days)
            data.append('embalming_excess_charge', this.embalming_excess_charge)
            data.append('days_of_embalming', this.days_of_embalming)
            data.append('embalming_excess', this.embalming_excess)
            data.append('viewing_type', this.viewing_type_room)
            data.append('viewing_type_free_days', this.chapel_view_free_days)
            data.append('viewing_type_excess_charge', this.viewing_type_excess_charge)
            data.append('chapel_view', this.chapel_view)
            data.append('viewing_excess', this.viewing_excess)
            data.append('other_charges', this.other_charges)
            data.append('others_reason', this.others_reason)
            data.append('special_price', this.special_price)
            data.append('shipment_fee', this.shipment_fee)
            data.append('tribute_fee', this.tribute_fee)
            data.append('hospital_bill', this.hospital_bill)
            data.append('total', this.total)
            data.append('year', this.year)
            data.append('casket_inventory_id', this.casket_inventory_id)
            data.append('month_of', this.month_of)
            data.append('user_id', this.user_id)
            data.append('discount', this.discount)
            data.append('payable_on_damayan', this.payable_on_damayan)
            data.append('damayan_category', this.damayan_category)
            data.append('chapel_share', this.chapel_share)
            data.append('ho_share', this.ho_share)
            this.save_new_deceased_client(data)
              .then(response => {
                var color = 'success'
                if (response.status === 201) {
                  color = 'error'
                } else {
                  this.print_contact()
                }
                this.change_snackbar({
                  show: true,
                  color: color,
                  text: response.data,
                })
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
          } else {
            this.alert = true
            this.alert_message = 'Please Fill up the field/s'
            this.saving = false
          }
        }
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      print_contact() {
        var payments_array = []
        var data_array = [180, 60, 60, 60, 60, 60,]
        payments_array.push(
          [
            {text: 'SERVICE PACKAGE', alignment: 'center', style: 'label'},
            {text: '1', alignment: 'center', style: 'label'},
            {text: '2', alignment: 'center', style: 'label'},
            {text: '3', alignment: 'center', style: 'label'},
            {text: '4', alignment: 'center', style: 'label'},
            {text: '5', alignment: 'center', style: 'label'},
          ]
        )
        payments_array.push(
          [
            {text: '1. Memorial Chapel (3 days Free)', alignment: 'left'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
          ]
        )
        payments_array.push(
          [
            {text: '2. Casket', alignment: 'left'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
          ]
        )
        payments_array.push(
          [
            {text: '3. Viewing sets', alignment: 'left'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
          ]
        )
        payments_array.push(
          [
            {text: '4. Body Retrieval (Pick-up and Deliver)', alignment: 'left'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
          ]
        )
        payments_array.push(
          [
            {text: '5. Ribbon Names', alignment: 'left'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
          ]
        )
        payments_array.push(
          [
            {text: '6. Flowers', alignment: 'left'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
          ]
        )
        payments_array.push(
          [
            {text: '7. 1 Table', alignment: 'left'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
          ]
        )
        payments_array.push(
          [
            {text: '8. 10 Chairs', alignment: 'left'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
          ]
        )
        payments_array.push(
          [
            {text: '9. Tarpaulin', alignment: 'left'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
          ]
        )
        payments_array.push(
          [
            {text: '10. 1 Water and Despenser', alignment: 'left'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
          ]
        )
        payments_array.push(
          [
            {text: '11. Picture Frame', alignment: 'left'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
          ]
        )
        payments_array.push(
          [
            {text: '12. Tribute (Junior Half Glass Casket and Up)', alignment: 'left'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
          ]
        )
        payments_array.push(
          [
            {text: '13. Balloons (Metal Casket Only)', alignment: 'left'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
            {text: '', alignment: 'center'},
          ]
        )

        var imgData = this.company_logo

        var caller_name = ''
        if (
          this.callers_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.callers_id) > -1
        ) {
          var caller_name =
            this.callers_items[
              this.callers_items
                .map(function (x) {
                  return x.id
                })
                .indexOf(this.callers_id)
              ].full_name
        }
        var driver_pick_up = '_________________________'
        var driver_pick_up_amount = '_________________________'
        var driver_pick_up_signature = '_________________________'
        var helper_pick_up = '_________________________'
        var helper_pick_up_amount = '_________________________'
        var helper_pick_up_signature = '_________________________'
        var embalmer = '_________________________'
        var embalmer_amount = '_________________________'
        var embalmer_signature = '_________________________'
        var undertaker = '_________________________'
        var undertaker_amount = '_________________________'
        var undertaker_signature = '_________________________'
        var driver_deliver = '_________________________'
        var driver_deliver_amount = '_________________________'
        var driver_deliver_signature = '_________________________'
        var helper_deliver = '_________________________'
        var helper_deliver_amount = '_________________________'
        var helper_deliver_signature = '_________________________'
        var tarp = '_________________________'
        var tarp_amount = '_________________________'
        var tarp_signature = '_________________________'
        var flowers = '_________________________'
        var flowers_amount = '_________________________'
        var flowers_signature = '_________________________'
        var driver_interment = '_________________________'
        var driver_interment_amount = '_________________________'
        var driver_interment_signature = '_________________________'
        var helper_interment = '_________________________'
        var helper_interment_amount = '_________________________'
        var helper_interment_signature = '_________________________'
        var driver_tribute = '_________________________'
        var driver_tribute_amount = '_________________________'
        var driver_tribute_signature = '_________________________'

        // for (var x = 0; x < this.list_of_client[index].fund_data.length; x++) {
        //     var data = this.list_of_client[index].fund_data[x]
        //     if (data.particulars == 'driver_pick_up') {
        //         driver_pick_up = data.payee
        //         driver_pick_up_amount = data.amount
        //         driver_pick_up_signature = '_________________________'
        //     }
        //     if (data.particulars == 'helper_pick_up') {
        //         helper_pick_up = data.payee
        //         helper_pick_up_amount = data.amount
        //         helper_pick_up_signature = '_________________________'
        //     }
        //     if (data.particulars == 'embalmer') {
        //         embalmer = data.payee
        //         embalmer_amount = data.amount
        //         embalmer_signature = '_________________________'
        //     }
        //     if (data.particulars == 'undertaker') {
        //         undertaker = data.payee
        //         undertaker_amount = data.amount
        //         undertaker_signature = '_________________________'
        //     }
        //     if (data.particulars == 'driver_deliver') {
        //         driver_deliver = data.payee
        //         driver_deliver_amount = data.amount
        //         driver_deliver_signature = '_________________________'
        //     }
        //     if (data.particulars == 'helper_deliver') {
        //         helper_deliver = data.payee
        //         helper_deliver_amount = data.amount
        //         helper_deliver_signature = '_________________________'
        //     }
        //     if (data.particulars == 'tarp') {
        //         tarp = data.payee
        //         tarp_amount = data.amount
        //         tarp_signature = '_________________________'
        //     }
        //     if (data.particulars == 'flowers') {
        //         flowers = data.payee
        //         flowers_amount = data.amount
        //         flowers_signature = '_________________________'
        //     }
        //     if (data.particulars == 'driver_interment') {
        //         driver_interment = data.payee
        //         driver_interment_amount = data.amount
        //         driver_interment_signature = '_________________________'
        //     }
        //     if (data.particulars == 'helper_interment') {
        //         helper_interment = data.payee
        //         helper_interment_amount = data.amount
        //         helper_interment_signature = '_________________________'
        //     }
        //     if (data.particulars == 'driver_tribute') {
        //         driver_tribute = data.payee
        //         driver_tribute_amount = data.amount
        //         driver_tribute_signature = '_________________________'
        //     }
        // }
        var ttotal = (this.mode_of_service === 'Damayan HG Package' || this.mode_of_service === 'Damayan FG Package') ? parseFloat(this.payable_on_damayan) + parseFloat(this.charges) : (parseFloat(this.payable_on_chapel) > 0 ? parseFloat(this.payable_on_chapel) + parseFloat(this.payable_on_damayan) + parseFloat(this.charges) : parseFloat(this.total) + parseFloat(this.payable_on_damayan))
        var ttotal2 = (this.mode_of_service === 'Damayan HG Package' || this.mode_of_service === 'Damayan FG Package') ? parseFloat(this.ho_share) + parseFloat(this.charges) : (parseFloat(this.payable_on_chapel) > 0 ? parseFloat(this.payable_on_chapel) + parseFloat(this.ho_share) + parseFloat(this.charges) : parseFloat(this.total))

        var damayan_data = [{
          text: ['-\n-'
          ],
        }, {
          text: ['-\n-'
          ],
        }, {
          text: ['-\n-'
          ],
        },]
        var damayan_data2 = [{
          text: ['-\n-'
          ],
        }, {
          text: ['-\n-'
          ],
        }, {
          text: ['-\n-'
          ],
        },];
        if (this.damayan_category != '') {
          if (this.damayan_category === 'WAIVED') {
            damayan_data = [
              {
                text: [''
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Damayan Discount: ',
                  {
                    text: this.formatPrice(parseFloat(this.payable_on_damayan)),
                    style: {
                      fontSize: 11,
                      color: 'blue',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Client Chapel Payables: ',
                  {
                    text: this.formatPrice(
                      this.mode_of_service === 'Damayan HG Package' || this.mode_of_service === 'Damayan FG Package'
                        ? 0
                        : 0 + parseFloat(this.casket_price_new),
                    ),
                    style: {
                      fontSize: 11,
                      color: 'green',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
            ]
            damayan_data2 = [
              {
                style: 'main_info',
                text: [
                  'HO Share: ',
                  {
                    text: this.formatPrice(parseFloat(this.ho_share)),
                    style: {
                      fontSize: 11,
                      color: 'red',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Damayan Discount: ',
                  {
                    text: this.formatPrice(parseFloat(this.payable_on_damayan)),
                    style: {
                      fontSize: 11,
                      color: 'blue',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Client Chapel Payables: ',
                  {
                    text: this.formatPrice(
                      this.mode_of_service === 'Damayan HG Package' || this.mode_of_service === 'Damayan FG Package'
                        ? 0
                        : 0 + parseFloat(this.casket_price_new),
                    ),
                    style: {
                      fontSize: 11,
                      color: 'green',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
            ]
          } else {
            ttotal = (this.mode_of_service === 'Damayan HG Package' || this.mode_of_service === 'Damayan FG Package') ? parseFloat(this.payable_on_damayan) + parseFloat(this.charges) : (parseFloat(this.payable_on_chapel) > 0 ? parseFloat(this.payable_on_chapel) + parseFloat(this.payable_on_damayan) + parseFloat(this.charges) : parseFloat(this.total) + parseFloat(this.payable_on_damayan))
            ttotal2 = (this.mode_of_service === 'Damayan HG Package' || this.mode_of_service === 'Damayan FG Package') ? parseFloat(this.ho_share) + parseFloat(this.charges) : (parseFloat(this.payable_on_chapel) > 0 ? parseFloat(this.payable_on_chapel) + parseFloat(this.ho_share) + parseFloat(this.charges) : parseFloat(this.total))
            damayan_data = [
              {
                text: [''
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Client Damayan Payables: ',
                  {
                    text: this.formatPrice(parseFloat(this.payable_on_damayan)),
                    style: {
                      fontSize: 11,
                      color: 'blue',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Client Chapel Payables: ',
                  {
                    text: this.formatPrice(
                      this.mode_of_service === 'Damayan HG Package' || this.mode_of_service === 'Damayan FG Package'
                        ? 0
                        : parseFloat(this.payable_on_chapel),
                    ),
                    style: {
                      fontSize: 11,
                      color: 'green',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
            ]
            damayan_data2 = [
              {
                style: 'main_info',
                text: [
                  'HO Share: ',
                  {
                    text: this.formatPrice(parseFloat(this.ho_share)),
                    style: {
                      fontSize: 11,
                      color: 'red',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Client Damayan Payables: ',
                  {
                    text: this.formatPrice(parseFloat(this.payable_on_damayan)),
                    style: {
                      fontSize: 11,
                      color: 'blue',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Client Chapel Payables: ',
                  {
                    text: this.formatPrice(
                      this.mode_of_service === 'Damayan HG Package' || this.mode_of_service === 'Damayan FG Package'
                        ? 0
                        : parseFloat(this.payable_on_chapel),
                    ),
                    style: {
                      fontSize: 11,
                      color: 'green',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
            ]
          }
        }
        var service_fee = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Mode: ',
                  {
                    text: this.mode_of_service,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Casket Type: ',
                  {
                    text: this.casket_type,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Discription: ',
                  {
                    text: this.description,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Casket Price: ',
                  {
                    text: this.formatPrice(
                      this.mode_of_service === 'Damayan HG Package' || this.mode_of_service === 'Damayan FG Package'
                        ? 0
                        : parseFloat(this.casket_price),
                    ),
                    style: {
                      fontSize: 11,
                      color: 'red',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Casket Discount: ',
                  {
                    text: this.formatPrice(this.discount),
                    style: {
                      fontSize: 11,
                      color: 'blue',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Casket Net Price: ',
                  {
                    text: this.formatPrice(
                      +(this.mode_of_service === 'Damayan HG Package' || this.mode_of_service === 'Damayan FG Package')
                        ? 0
                        : 0 + parseFloat(this.casket_price_new),
                    ),
                    style: {
                      fontSize: 11,
                      color: 'green',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
            ],
          },
          {
            columns: damayan_data,
          },
        ]
        var service_fee2 = [
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Mode: ',
                  {
                    text: this.mode_of_service,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Casket Type: ',
                  {
                    text: this.casket_type,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Discription: ',
                  {
                    text: this.description,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Casket Price: ',
                  {
                    text: this.formatPrice(
                      this.mode_of_service === 'Damayan HG Package' || this.mode_of_service === 'Damayan FG Package'
                        ? 0
                        : parseFloat(this.casket_price),
                    ),
                    style: {
                      fontSize: 11,
                      color: 'red',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Casket Discount: ',
                  {
                    text: this.formatPrice(this.discount),
                    style: {
                      fontSize: 11,
                      color: 'blue',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Casket Net Price: ',
                  {
                    text: this.formatPrice(
                      +(this.mode_of_service === 'Damayan HG Package' || this.mode_of_service === 'Damayan FG Package')
                        ? 0
                        : 0 + parseFloat(this.casket_price_new),
                    ),
                    style: {
                      fontSize: 11,
                      color: 'green',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
            ],
          },
          {
            columns: damayan_data2,
          },
        ]
        var data_prices = [
          {
            columns: [
              {
                style: 'main_info',
                text: [''
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Kilometer Excess: ',
                  {
                    text: this.formatPrice(this.kilometer_excess),
                    style: 'main_data',
                  },
                ],
              },

              {
                style: 'main_info',
                text: [
                  'Tribute Fee: ',
                  {
                    text: this.formatPrice(this.tribute_fee),
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [''
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Embalming Excess: ',
                  {
                    text: this.formatPrice(this.embalming_excess),
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Hospital Bill: ',
                  {
                    text: this.formatPrice(this.hospital_bill),
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Kilometer: ',
                  {
                    text: this.kilometer + ' KM/s',
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Viewing Excess: ',
                  {
                    text: this.formatPrice(this.viewing_excess),
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Accomodation Charge: ',
                  {
                    text: this.formatPrice(this.accomodation_charge),
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Days of Embalming: ',
                  {
                    text: this.days_of_embalming + ' DAY/s',
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Other Charges: ',
                  {
                    text: this.formatPrice(this.other_charges),
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: ['Specify(Others): '],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Chapel Viewing: ',
                  {
                    text: this.chapel_view + ' DAY/s',
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Special Price: ',
                  {
                    text: this.formatPrice(this.special_price),
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  {
                    text: this.others_reason,
                    style: 'main_data_2',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Viewing at: ',
                  {
                    text: this.viewing_type,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Shipment Fee: ',
                  {
                    text: this.formatPrice(this.shipment_fee),
                    style: 'main_data',
                  },
                ],
              },
              {},
            ],
          },
          {
            columns: [
              {text: ''},
              {
                style: {fontSize: 15},
                text: [
                  'Php Total: ',
                  {
                    text: this.formatPrice(ttotal),
                    style: {fontsize: 20, color: 'red', bold: true},
                  },
                ],
              },
              {text: ''},
            ],
          },
        ]
        var data_prices2 = [
          {
            columns: [
              {
                style: 'main_info',
                text: [''
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Kilometer Excess: ',
                  {
                    text: this.formatPrice(this.kilometer_excess),
                    style: 'main_data',
                  },
                ],
              },

              {
                style: 'main_info',
                text: [
                  'Tribute Fee: ',
                  {
                    text: this.formatPrice(this.tribute_fee),
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [''
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Embalming Excess: ',
                  {
                    text: this.formatPrice(this.embalming_excess),
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Hospital Bill: ',
                  {
                    text: this.formatPrice(this.hospital_bill),
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Kilometer: ',
                  {
                    text: this.kilometer + ' KM/s',
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Viewing Excess: ',
                  {
                    text: this.formatPrice(this.viewing_excess),
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Accomodation Charge: ',
                  {
                    text: this.formatPrice(this.accomodation_charge),
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Days of Embalming: ',
                  {
                    text: this.days_of_embalming + ' DAY/s',
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Other Charges: ',
                  {
                    text: this.formatPrice(this.other_charges),
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: ['Specify(Others): '],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Chapel Viewing: ',
                  {
                    text: this.chapel_view + ' DAY/s',
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Special Price: ',
                  {
                    text: this.formatPrice(this.special_price),
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  {
                    text: this.others_reason,
                    style: 'main_data_2',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Viewing at: ',
                  {
                    text: this.viewing_type,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Shipment Fee: ',
                  {
                    text: this.formatPrice(this.shipment_fee),
                    style: 'main_data',
                  },
                ],
              },
              {},
            ],
          },
          {
            columns: [
              {text: ''},
              {
                style: {fontSize: 15},
                text: [
                  'Php Total: ',
                  {
                    text: this.formatPrice(ttotal2),
                    style: {fontsize: 20, color: 'red', bold: true},
                  },
                ],
              },
              {text: ''},
            ],
          },
        ]
        if (this.mode_of_service === 'Tie Up') {
          data_prices = [
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Mode: ',
                    {
                      text: this.mode_of_service,
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Casket Price: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Shipment Fee: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Discription: ',
                    {
                      text: this.description,
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Kilometer Excess: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Tribute Fee: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Casket Type: ',
                    {
                      text: this.casket_type,
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Embalming Excess: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Hospital Bill: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Kilometer: ',
                    {
                      text: this.kilometer,
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Viewing Excess: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Accomodation Charge: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Days of Embalming: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Other Charges: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: ['Specify(Others): '],
                },
              ],
            },
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Chapel Viewing: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Special Price: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  text: '',
                },
                {
                  style: {fontSize: 15},
                  text: [
                    'Php Total: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
                {text: ''},
              ],
            },
          ]
        }
        //Always Copy this into info 2
        var info1 = [
          {
            columns: [
              {image: imgData, width: 54, height: 54, style: 'logo'},
              {
                stack: [
                  'GOODLIFE MEMORIAL CHAPEL CO.',
                  {text: this.branch_address, style: 'subheader'},
                  {
                    text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                    style: 'subheader',
                  },
                  {
                    text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                    style: 'printed_label',
                  },
                ],
                style: 'header',
              },
            ],
          },
          '__________________________________________________________________________________________________',
          {
            stack: [
              {
                columns: [
                  {text: 'Branch: ' + this.branch, style: 'main_info'},
                  {
                    text: 'FUNERAL SERVICE CONTRACT',
                    style: {
                      fontSize: 16,
                      alignment: 'center',
                      bold: true,
                      color: 'blue',
                    },
                  },
                  {
                    text: 'FSC No.: ' + this.fsc_no,
                    style: {
                      margin: [0, 2, 0, 2],
                      fontSize: 13,
                      alignment: 'right',
                      color: 'red',
                      bold: true,
                    },
                  },
                ],
              },
            ],
          },
          '__________________________________________________________________________________________________',
          {
            stack: [
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Date of Service: ',
                      {
                        text: moment(this.date_of_service, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {text: ''},
                  {
                    style: 'main_info',
                    text: [
                      'Date of Interment: ',
                      {
                        text: this.date_of_internment,
                        style: 'main_data_2',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Name of Cadaver: ',
                      {
                        text:
                          this.last_name.toUpperCase() +
                          ', ' +
                          this.first_name.toUpperCase() +
                          ' ' +
                          this.middle_name.toUpperCase(),
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          '__________________________________________________________________________________________________',
          {
            stack: [
              {
                columns: [
                  {
                    style: 'main_info',
                    text: ['Gender: ', {text: this.gender, style: 'main_data'}],
                  },
                  {
                    style: 'main_info',
                    text: [
                      'Date of Birth: ',
                      {
                        text: moment(this.date_of_birth, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    style: 'main_info',
                    text: [
                      'Age: ',
                      {
                        text: this.age,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Civil Status: ',
                      {
                        text: this.civil_status,
                        style: 'main_data',
                      },
                    ],
                  },
                  {
                    style: 'main_info',
                    text: [
                      'Date of Death: ',
                      {
                        text: moment(this.date_of_death, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {text: ''},
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Religion: ',
                      {
                        text: this.religion,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          '__________________________________________________________________________________________________',
          'FAMILY REPRESENTATIVES',
          {
            stack: [
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Name: ',
                      {
                        text: this.representatives_name.toUpperCase(),
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Relationship: ',
                      {
                        text: this.representatives_relationship.toUpperCase(),
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Address: ',
                      {
                        text: this.representatives_address.toUpperCase(),
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Contact No.: ',
                      {
                        text: this.representatives_contact_no,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          '__________________________________________________________________________________________________',
          'SUMMARY OF SERVICE',
          {
            stack: service_fee,
          },
          '__________________________________________________________________________________________________',
          'SUMMARY OF CHARGES',
          {
            stack: data_prices,
          },
          '__________________________________________________________________________________________________',
          {
            stack: [
              {
                columns: [
                  {
                    table: {
                      body: [
                        [
                          {
                            stack: [
                              'FUNERAL SERVICE PACKAGES',
                              {
                                style: {alignment: 'justify', fontSize: 9},
                                ul: [
                                  'Casket',
                                  '9 days viewing',
                                  '3 days Chapel Viewing on NON AIR',
                                  'Free 25km',
                                  'Tarpaulin',
                                  'Tables and Chairs',
                                  'Funeral',
                                  'Funeral Hearse/Car',
                                  'Dispenser/Heater Upon Availability',
                                ],
                              },
                            ],
                          },
                        ],
                      ],
                    },
                  },
                  {
                    text: 'POLICY AND AGREEMENTS',
                    style: {alignment: 'justify', fontSize: 9},
                    ol: [
                      'Pick up service from residence/hospital to chapel.',
                      'Delivery from chapel to viewing residence.',
                      'Delivery from viewing place to internment site.',
                      'Family shall notice the Office (3) days in advance for the date and time of internment otherwise the burial will depend on the funeral coaches.',
                      'GOODLIFE CHAPELS will collect funeral equipments after burial service, damages to equipment will be charge accordingly.',
                      'Service payment must be full paid 2 days before internment.',
                      'The policy of GOODLIFE CHAPELS is "NO PAYMENT, NO INTERNMENT", the funeral car of the chapel will not bet used until the family has existing balance.',
                    ],
                  },
                ],
              },
              '',
              {
                columns: [
                  {
                    text: 'I hereby agree and understand the terms and conditions this contract with GOODLIFE MEMORIAL CHAPELS CO.',
                    style: {fontSize: 10},
                  },
                ],
              },
            ],
          },
        ]
        var info2 = [
          {
            columns: [
              {image: imgData, width: 54, height: 54, style: 'logo'},
              {
                stack: [
                  'GOODLIFE MEMORIAL CHAPEL CO.',
                  {text: this.branch_address, style: 'subheader'},
                  {
                    text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                    style: 'subheader',
                  },
                  {
                    text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                    style: 'printed_label',
                  },
                ],
                style: 'header',
              },
            ],
          },
          '__________________________________________________________________________________________________',
          {
            stack: [
              {
                columns: [
                  {text: 'Branch: ' + this.branch, style: 'main_info'},
                  {
                    text: 'FUNERAL SERVICE CONTRACT',
                    style: {
                      fontSize: 16,
                      alignment: 'center',
                      bold: true,
                      color: 'blue',
                    },
                  },
                  {
                    text: 'FSC No.: ' + this.fsc_no,
                    style: {
                      margin: [0, 2, 0, 2],
                      fontSize: 13,
                      alignment: 'right',
                      color: 'red',
                      bold: true,
                    },
                  },
                ],
              },
            ],
          },
          '__________________________________________________________________________________________________',
          {
            stack: [
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Date of Service: ',
                      {
                        text: moment(this.date_of_service, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {text: ''},
                  {
                    style: 'main_info',
                    text: [
                      'Date of Interment: ',
                      {
                        text: this.date_of_internment,
                        style: 'main_data_2',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Name of Cadaver: ',
                      {
                        text:
                          this.last_name.toUpperCase() +
                          ', ' +
                          this.first_name.toUpperCase() +
                          ' ' +
                          this.middle_name.toUpperCase(),
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          '__________________________________________________________________________________________________',
          {
            stack: [
              {
                columns: [
                  {
                    style: 'main_info',
                    text: ['Gender: ', {text: this.gender, style: 'main_data'}],
                  },
                  {
                    style: 'main_info',
                    text: [
                      'Date of Birth: ',
                      {
                        text: moment(this.date_of_birth, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    style: 'main_info',
                    text: [
                      'Age: ',
                      {
                        text: this.age,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Civil Status: ',
                      {
                        text: this.civil_status,
                        style: 'main_data',
                      },
                    ],
                  },
                  {
                    style: 'main_info',
                    text: [
                      'Date of Death: ',
                      {
                        text: moment(this.date_of_death, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {text: ''},
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Religion: ',
                      {
                        text: this.religion,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          '__________________________________________________________________________________________________',
          'FAMILY REPRESENTATIVES',
          {
            stack: [
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Name: ',
                      {
                        text: this.representatives_name.toUpperCase(),
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Relationship: ',
                      {
                        text: this.representatives_relationship.toUpperCase(),
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Address: ',
                      {
                        text: this.representatives_address.toUpperCase(),
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Contact No.: ',
                      {
                        text: this.representatives_contact_no,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          '__________________________________________________________________________________________________',
          'SUMMARY OF SERVICE',
          {
            stack: service_fee2,
          },
          '__________________________________________________________________________________________________',
          'SUMMARY OF CHARGES',
          {
            stack: data_prices2,
          },
          '__________________________________________________________________________________________________',
          {
            stack: [
              {
                columns: [
                  {
                    table: {
                      body: [
                        [
                          {
                            stack: [
                              'FUNERAL SERVICE PACKAGES',
                              {
                                style: {alignment: 'justify', fontSize: 9},
                                ul: [
                                  'Casket',
                                  '9 days viewing',
                                  '3 days Chapel Viewing on NON AIR',
                                  'Free 25km',
                                  'Tarpaulin',
                                  'Tables and Chairs',
                                  'Funeral',
                                  'Funeral Hearse/Car',
                                  'Dispenser/Heater Upon Availability',
                                ],
                              },
                            ],
                          },
                        ],
                      ],
                    },
                  },
                  {
                    text: 'POLICY AND AGREEMENTS',
                    style: {alignment: 'justify', fontSize: 9},
                    ol: [
                      'Pick up service from residence/hospital to chapel.',
                      'Delivery from chapel to viewing residence.',
                      'Delivery from viewing place to internment site.',
                      'Family shall notice the Office (3) days in advance for the date and time of internment otherwise the burial will depend on the funeral coaches.',
                      'GOODLIFE CHAPELS will collect funeral equipments after burial service, damages to equipment will be charge accordingly.',
                      'Service payment must be full paid 2 days before internment.',
                      'The policy of GOODLIFE CHAPELS is "NO PAYMENT, NO INTERNMENT", the funeral car of the chapel will not bet used until the family has existing balance.',
                    ],
                  },
                ],
              },
              '',
              {
                columns: [
                  {
                    text: 'I hereby agree and understand the terms and conditions this contract with GOODLIFE MEMORIAL CHAPELS CO.',
                    style: {fontSize: 10},
                  },
                ],
              },
            ],
          },
        ]
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: {
            width: 612,
            height: 936,
          },
          pageOrientation: 'portrait',
          content: [
            info1,
            '__________________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.representatives_name.toUpperCase(),
                      style: {fontSize: 10},
                    },
                    {
                      text: this.chapel_manager.toUpperCase(),
                      style: {fontSize: 10},
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'FAMILY REPRESENTATIVES SIGNATURE',
                      style: {fontSize: 10},
                    },
                    {
                      text: 'CHAPEL MANAGER',
                      style: {fontSize: 10},
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'AUDITOR',
                      style: {fontSize: 10},
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      table: {
                        body: [
                          [
                            {
                              fillColor: '#0039f5',
                              stack: [
                                {
                                  text: 'CLIENT COPY',
                                  style: {fontSize: 16, color: 'white'},
                                },
                              ],
                            },
                          ],
                        ],
                      },
                    },
                  ],
                },
              ],
            },
            info2,
            '__________________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.representatives_name.toUpperCase(),
                      style: {fontSize: 10},
                    },
                    {
                      text: this.chapel_manager.toUpperCase(),
                      style: {fontSize: 10},
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'FAMILY REPRESENTATIVES SIGNATURE',
                      style: {fontSize: 10},
                    },
                    {
                      text: 'CHAPEL MANAGER',
                      style: {fontSize: 10},
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: caller_name,
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'CALLERS SIGNATURE',
                      style: {fontSize: 10},
                    },
                    {
                      text: 'AUDITOR',
                      style: {fontSize: 10},
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      table: {
                        body: [
                          [
                            {
                              fillColor: '#f7e307',
                              stack: [
                                {
                                  text: 'GOODLIFE COPY',
                                  style: {fontSize: 16, color: 'blacK'},
                                },
                              ],
                            },
                          ],
                        ],
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE MEMORIAL CHAPEL CO.',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            '__________________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {text: ' ', style: 'main_info'},
                    {
                      text: 'WAIVER OF LIABILITIES',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                        color: 'blue',
                      },
                    },
                    {text: ' ', style: 'main_info'},
                  ],
                },
              ],
            },
            '\n\n\n',
            'Goodlife Memorial Chapel is offered to embalm of cadaver and a complete package of Funeral equipment used during the "FUNERAL WAKE"' +
            ' vigil of the Family and Funeral Car. LAPIDA, CANDLE, BARONG or any suit/dress of cadaver have not been offered and its contract by Memorial Chapel.',
            '\n\n\n',
            'I _________________________________________ residence of ________________________________________ representative of late FSC #: ' +
            this.fsc_no +
            ' rendered service by GOODLIFE MEMORIAL CHAPEL-' +
            this.branch +
            ' branch are not liable or any one of my family to hold the equipment or file any complain beyond' +
            ' Goodlife Memorial Service even the transaction between the staff and client.',
            '\n\n\n\n\n',
            {text: this.representatives_name.toUpperCase()},
            '____________________________________________\nNAME & SIGNATURE OF REPRESENTATIVE',
            '\n\n\n',
            {text: this.chapel_manager.toUpperCase()},
            '____________________________________________\nCHAPEL MANAGER',
            '\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n',

            //CLIEN"TS FEEDBACK FORM
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE MEMORIAL CHAPEL CO.',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            '__________________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {text: 'Branch: ' + this.branch, style: 'main_info'},
                    {
                      text: 'CLIENTS FEEDBACK FORM',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                        color: 'blue',
                      },
                    },
                    {
                      text: 'FSC No.: ' + this.fsc_no,
                      style: {
                        margin: [0, 2, 0, 2],
                        fontSize: 13,
                        alignment: 'right',
                        color: 'red',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'Our business strives to provide excellent customer service. We have offered the service package and would like to know if you think our services are satisfactory. The lowest value on a scale of 1 to 5 is 1, and the highest value is 5.',
                      style: 'main_info'
                    },
                  ],
                },
              ],
            },
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: data_array,
                body: payments_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'Note: For Damayan Services and Upgrade (Ribbon Names, Tarpaulin, Flowers and Tribute) The branch will proviced.',
                      style: 'main_info'
                    },
                  ],
                },
              ],
            }, '\n',
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'If you have any Suggestions or Recommendations kindly write it below.',
                      style: 'main_info'
                    },
                  ],
                },
              ],
            }, '\n',
            '__________________________________________________________________________________________________', '\n',
            '__________________________________________________________________________________________________', '\n',
            '__________________________________________________________________________________________________', '\n',
            {
              stack: [
                {
                  columns: [
                    {text: 'Kindly rate our services from 1-10. And why?', style: 'main_info'},
                  ],
                },
              ],
            }, '\n',
            '__________________________________________________________________________________________________', '\n',
            '__________________________________________________________________________________________________', '\n',
            '__________________________________________________________________________________________________', '\n\n\n\n',
            {
              stack: [
                {
                  columns: [
                    {text: this.representatives_name.toUpperCase()},
                    {text: ''},
                    {text: this.chapel_manager.toUpperCase()},
                  ],
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {text: '___________________________'},
                    {text: '___________________________'},
                    {text: '___________________________'},
                  ],
                },
              ],
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'NAME & SIGNATURE OF REPRESENTATIVE'},
                    {text: 'CHAPEL STAFF'},
                    {text: 'CHAPEL MANAGER'},
                  ],
                },
              ],
              pageBreak: 'after'
            },

            //LIQUIDATION FORMS

            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE MEMORIAL CHAPEL CO.',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            '__________________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {text: 'Branch: ' + this.branch, style: 'main_info'},
                    {
                      text: 'LIQUIDATION FORM',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                        color: 'blue',
                      },
                    },
                    {
                      text: 'FSC No.: ' + this.fsc_no,
                      style: {
                        margin: [0, 2, 0, 2],
                        fontSize: 13,
                        alignment: 'right',
                        color: 'red',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            '__________________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Date of Service: ',
                        {
                          text: moment(this.date_of_service, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                          style: 'main_data_2',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Date of Interment: ',
                        {
                          text: this.date_of_internment,
                          style: 'main_data_2',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Name of Cadaver: ',
                        {
                          text:
                            this.last_name.toUpperCase() +
                            ', ' +
                            this.first_name.toUpperCase() +
                            ' ' +
                            this.middle_name.toUpperCase(),
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Address: ',
                        {
                          text: this.representatives_address.toUpperCase(),
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: ['Gender: ', {text: this.gender, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [
                        'Date of Birth: ',
                        {
                          text: moment(this.date_of_birth, 'YYYY-MM-DD').format('MMMM DD, YYYY'),
                          style: 'main_data_2',
                        },
                      ],
                    },
                    {
                      style: 'main_info',
                      text: [
                        'Age: ',
                        {
                          text: this.age,
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Civil Status: ',
                        {
                          text: this.civil_status,
                          style: 'main_data',
                        },
                      ],
                    },
                    {
                      style: 'main_info',
                      text: [
                        'Date of Death: ',
                        {
                          text: moment(this.date_of_death, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                          style: 'main_data_2',
                        },
                      ],
                    },
                    {text: ''},
                  ],
                },
              ],
            },

            '__________________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'PARTICULARS', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: 'NAME', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: 'AMOUNT', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: 'SIGNATURE', style: 'main_data'}],
                    },
                  ],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'Driver Pick-up', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: driver_pick_up, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: driver_pick_up_amount, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: driver_pick_up_signature, style: 'main_data'}],
                    },
                  ],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'Helper Pick-up', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: helper_pick_up, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: helper_pick_up_amount, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: helper_pick_up_signature, style: 'main_data'}],
                    },
                  ],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'Embalmer', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: embalmer, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: embalmer_amount, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: embalmer_signature, style: 'main_data'}],
                    },
                  ],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'Undertaker', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: undertaker, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: undertaker_amount, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: undertaker_signature, style: 'main_data'}],
                    },
                  ],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'Driver Deliver', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: driver_deliver, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: driver_deliver_amount, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: driver_deliver_signature, style: 'main_data'}],
                    },
                  ],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'Helper Deliver', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: helper_deliver, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: helper_deliver_amount, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: helper_deliver_signature, style: 'main_data'}],
                    },
                  ],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'Tarp', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: tarp, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: tarp_amount, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: tarp_signature, style: 'main_data'}],
                    },
                  ],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'Flowers', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: flowers, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: flowers_amount, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: flowers_signature, style: 'main_data'}],
                    },
                  ],
                },
              ],
            },

            //Internment
            {
              columns: [{text: ' '}],
            },
            '_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _',
            {
              columns: [{text: ' '}],
            },

            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE MEMORIAL CHAPEL CO.',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            '__________________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {text: 'Branch: ' + this.branch, style: 'main_info'},
                    {
                      text: 'LIQUIDATION FORM',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                        color: 'blue',
                      },
                    },
                    {
                      text: 'FSC No.: ' + this.fsc_no,
                      style: {
                        margin: [0, 2, 0, 2],
                        fontSize: 13,
                        alignment: 'right',
                        color: 'red',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            '__________________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Date of Service: ',
                        {
                          text: moment(this.date_of_service, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                          style: 'main_data_2',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Date of Interment: ',
                        {
                          text: this.date_of_internment,
                          style: 'main_data_2',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Name of Cadaver: ',
                        {
                          text:
                            this.last_name.toUpperCase() +
                            ', ' +
                            this.first_name.toUpperCase() +
                            ' ' +
                            this.middle_name.toUpperCase(),
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Address: ',
                        {
                          text: this.representatives_address.toUpperCase(),
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: ['Gender: ', {text: this.gender, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [
                        'Date of Birth: ',
                        {
                          text: moment(this.date_of_birth, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                          style: 'main_data_2',
                        },
                      ],
                    },
                    {
                      style: 'main_info',
                      text: [
                        'Age: ',
                        {
                          text: this.age,
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Civil Status: ',
                        {
                          text: this.civil_status,
                          style: 'main_data',
                        },
                      ],
                    },
                    {
                      style: 'main_info',
                      text: [
                        'Date of Death: ',
                        {
                          text: moment(this.date_of_death, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                          style: 'main_data_2',
                        },
                      ],
                    },
                    {text: ''},
                  ],
                },
              ],
            },

            '__________________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'PARTICULARS', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: 'NAME', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: 'AMOUNT', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: 'SIGNATURE', style: 'main_data'}],
                    },
                  ],
                },

                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'Driver Interment', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: driver_interment, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: driver_interment_amount, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: driver_interment_signature, style: 'main_data'}],
                    },
                  ],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'Helper Internment', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: helper_interment, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: helper_interment_amount, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: helper_interment_signature, style: 'main_data'}],
                    },
                  ],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'Driver Tribute', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: driver_tribute, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: driver_tribute_amount, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: driver_tribute_signature, style: 'main_data'}],
                    },
                  ],
                },
              ],
            },
          ],
          footer: {
            // columns: [
            //     {
            //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
            //         alignment: 'center',
            //         style: 'tableExample'
            //     }
            // ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 11,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.reset()
      },
    },
  }
</script>
